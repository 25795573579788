<template>
  <div class="menber-dialog">
    <HeTong
      ref="hetong"
      v-if="showHt"
      :currentCode="identityObj.identityTypeCode == 2 ? 1 : 2"
      :currentItem="currentItem"
    />
    <el-dialog
      title="详情"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="560px"
    >
      <!-- <el-form ref="form" label-width="120px">
        <el-form-item label="项目详情:">{{
          currentItem.companyCode
        }}</el-form-item>
      </el-form> -->
      <el-form :inline="true" label-width="120px">
        <el-form-item label="托管人:" style="width: 220px">
          {{ currentItem.farmerName }}
        </el-form-item>
        <el-form-item label="联系电话:">
          {{ currentItem.farmerPhone }}
        </el-form-item>
      </el-form>
      <el-form ref="form" label-width="120px">
        <el-form-item label="托管类型:">
          {{ currentItem.orderType == 1 ? "全程托管" : "环节托管" }}
        </el-form-item>
        <el-form-item label="托管环节:">
          {{ currentItem.orderContent }}
        </el-form-item>
        <el-form-item label="作物类型:">
          {{ currentItem.orderContent }}
        </el-form-item>
        <el-form-item label="每季保底产量:">
          {{ currentItem.seasonYield }}
        </el-form-item>
        <el-form-item label="土地面积:">
          {{ currentItem.landArea }}亩
        </el-form-item>
        <el-form-item label="地址:">
          {{ currentItem.areaName }}
        </el-form-item>
        <el-form-item label="托管时间:">
          {{
            currentItem.startDate ? currentItem.startDate.substr(0, 10) : ""
          }}-{{ currentItem.endDate ? currentItem.endDate.substr(0, 10) : "" }}
        </el-form-item>
        <el-form-item label="总价:">
          {{ currentItem.estimatePrice }}元
        </el-form-item>
        <el-form-item label="其他说明:">
          {{ currentItem.remark }}
        </el-form-item>
        <el-form-item label="现场照片:">
          <el-image
            style="width: 100px; height: 100px"
            :src="item"
            v-for="(item, index) in currentItem.xczp"
            :key="index"
            :preview-src-list="currentItem.xczp"
          >
          </el-image>
        </el-form-item>
      </el-form>
      <div class="btns">
        <el-button size="small" type="info" @click="back">关闭</el-button
        ><el-button size="small" type="primary" @click="exportPdf"
          >我要接管并导出合同</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
  <script>
import { mapGetters } from "vuex";
import HeTong from "../../../quickLink/hetong.vue";
export default {
  data() {
    return {
      dialogVisible: true,
      showHt: false,
      baseUrl: window.globalUrl.HOME_API,
      currentItem: {},
    };
  },
  components: {
    HeTong,
  },
  mounted() {},
  computed: mapGetters(["userInfo", "identityObj"]),
  watch: {},
  methods: {
    init(obj) {
      let pzzp = [];
      if (obj.landCertificateUrl) {
        JSON.parse(obj.landCertificateUrl).forEach((item) => {
          pzzp.push(this.baseUrl + "admin/file/get?ossFilePath=" + item.url);
        });
      }
      obj.pzzp = pzzp;
      let xczp = [];
      if (obj.livePhotos) {
        JSON.parse(obj.livePhotos).forEach((item) => {
          xczp.push(this.baseUrl + "admin/file/get?ossFilePath=" + item.url);
        });
      }
      obj.xczp = xczp;
      this.currentItem = obj;
    },
    // 返回
    back() {
      this.$emit("closeDialog2", false);
    },
    // 关闭弹框
    handleClose() {
      this.$emit("closeDialog2", false);
    },
    // 导出合同
    exportPdf() {
      console.log(this.currentItem.orderState);
      if (this.currentItem.orderState == "01") {
        this.$message.info("此土地托管中，不可导出");
      } else if (this.currentItem.orderState == "02") {
        this.$message.info("此土地托管结束，不可导出");
        return;
      } else {
        this.showHt = true;
        setTimeout(() => {
          this.$refs.hetong.viewHt2(this.currentItem.orderId);
        }, 100);
      }
    },
  },
};
</script>
  <style lang="less" scoped>
/deep/.el-form-item {
  margin-bottom: 0 !important;
  .el-input {
    width: 260px;
  }
}
/deep/.el-dialog__body {
  padding-left: 30px;
}
.btns {
  display: flex;
  margin-top: 20px;
  padding: 0 120px;
  box-sizing: border-box;
  justify-content: space-between;
}
</style>
